@import '../../variables.scss';

.experience-item {
    &:nth-child(n+3) { margin-top: 16px; }
    h1 {
        font-size: 1.5rem;
        font-weight: 500;
        color: $color-grey-dark;
        padding-bottom: 8px;
    }
    h2 { 
        padding-bottom: 16px;
        font-weight: 400;
        .duration { 
            margin-left: 32px;
        }
    }
    p { 
        line-height: 24px;
    }
}

// -------------- PHONE --------------
@media (max-width: $phone) {
    .experience-item { 
        &:nth-child(n+2) { margin-top: 12px; }
    }
}