@import './_variables.scss';

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,400i,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:400,700&display=swap');

* {
    font-family: 'Roboto';
    box-sizing: border-box;
    margin: 0px;
    letter-spacing: 0.5px;
    line-height: 1.2;
    font-size: 16px;
    color: $color-grey-dark;
}

html {
    scroll-behavior: smooth;
}

a { text-decoration: none; }

ul, li { list-style: none outside none; }

body{
    overflow-x: hidden;
}

.body-section { 
    width: 1096px;
    max-width: 100vw;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: $margin-extra-large;
}

// -------------- PHONE --------------
@media (max-width: $phone) {
    .body-section { 
        margin-top: $margin-large;
        padding: 0px $margin-medium;
    }
}

// -------------- TABLET --------------
@media (min-width: $phone + 1) and (max-width: $tablet) { 
    .body-section { 
        margin-top: $margin-large ;
        padding: 0px $margin-large ;
    }
}
// ipad pro12.9in
@media (min-width: $desktop) and (max-width: $desktop) and (orientation: portrait) {
    .body-section { 
        margin-top: $margin-large ;
        padding: 0px $margin-large ;
    }
}