@import '../../variables.scss';

#home {
    top: 0;
    height: 100vh;
    width: 100vw;
    background: linear-gradient(135deg, $color-primary, $color-secondary);
    .home-title_container {
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 50%;
        width: 100vw;
        transform: translateY(-50%);
        align-items: center;
        h1, h2 { 
            animation: fadein 2.5s; 
            color: white;
            transform: translateX(2px); 
        }
        h1 {
            font-size: 8rem;
            font-weight: 300;
            letter-spacing: 24px;      
        }
        h2 {
            opacity: 0.8;
            font-size: 2rem;
            font-weight: 300;
            letter-spacing: 8px;
            margin-top: $margin-large;
        }
    }
    .home-background-logo {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -15%);
        height: 130vh;
        width: 100vw;
        opacity: 0.1;
        overflow: hidden;
        animation: fadeout 2s;
        object-fit: cover;
        pointer-events: none;
    }
    .social-links { 
        animation: fadein2 2s;
        padding: $margin-medium;
    }
}

.mouse-animation_container { 
    animation: fadein2 2s;
    bottom: 5vh;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

@keyframes fadein {
    0%   { 
        opacity: 0; 
        transform: translateY(-24px);
        letter-spacing: 4px;
    }
    20%   { 
        opacity: 0; 
        transform: translateY(-24px); 
    }
}

@keyframes fadein2 {
    0%   { opacity: 0; }
    20%   { opacity: 0; }
}

@keyframes fadeout {
    0%   { 
        opacity: 0.8; 
        height: 160vh;
        transform: translate(-50%, -18%);
    }
}

// -------------- PHONE --------------
@media (max-width: $phone) {
    #home {
        height: 95vh;
        .home-title_container {
            top: 45%;
            h1 { 
                font-size: 3rem;
                letter-spacing: 8px; 
            }
            h2 {
                font-size: 1rem;
                letter-spacing: 4px;
                margin-top: $margin-small;
            }
        }
        .mouse-animation_container { bottom: 10vh; }
        .social-links { padding: $margin-small; }
    }
}

// -------------- TABLET --------------
@media (min-width: $phone + 1) and (max-width: $tablet) {
    #home {
        height: 95vh;
        .home-title_container {
            top: 47%;
            h1 { 
                font-size: 6rem;
                letter-spacing: 16px; 
            }
            h2 {
                font-size: 1.5rem;
                letter-spacing: 6px;
                margin-top: $margin-medium;
            }
        }
        .mouse-animation_container { bottom: 12vh; }
    }
}