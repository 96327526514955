@import '../../variables.scss';

.nav-list {
    flex-direction: column;
    align-items: flex-end;
    transition: 0.6s ease;
    display: flex;
    li {
        transition: 0.6s ease;
        margin-top: $margin-large * 3/2;
        a {
            color: white;
            opacity: 0.8;
            font-size: 2rem;
            transition: 0.2s;
            .underline { 
                float: right;
                opacity: 0;
                width: 1%;
                height: 2px;
                margin-top: 4px;
                background-color: white;
                transition: 0.2s;
             }
            &:hover {
                opacity: 1;
                .underline { 
                    opacity: 1;
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: $phone) {
    .nav-list {
        li {
            margin-top: $margin-large;
            a { font-size: 1.5rem; }
        }
    }
}