@import '../../variables.scss';

header {
    position: relative;
    width: 100vw;
    background-color: $color-grey-dark;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $margin-large 0px;
    h1 {
        color: white;
        font-size: 3rem;
        font-weight: 400;
    }
    h2 {
        color: $color-grey-light;
        font-size: 2rem;
        font-weight: 200;
        margin-top: $margin-small;
    }
}

// -------------- PHONE --------------
@media (max-width: $phone) { 
    header {
        padding: $margin-medium 0px;
        h1 { font-size: 1.5rem; }
        h2 { 
            font-size: 1rem;
            margin-top: $margin-extra-small;
         }
    }
}