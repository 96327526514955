@import '../../variables.scss';

.button {
    cursor: pointer;
    transition-duration: 0.2s;
    padding: 8px 12px;
    border-radius: 24px;
    font-size: 1rem;
    &:hover {
        box-shadow: $box-shadow;
        transform: translateY(-1px);
    }
  
    &:active {
        box-shadow: none;
        transform: scale(1);
    }
  
    &:focus {
        outline: none;
    }
    &.button--filled {
        background-color: $color-grey-dark;
        color: white;
        border-style: none;
    }
    &.button--outlined {
        background-color: transparent;
        border-style: solid;
        //border-weight: 2px;
        border-color: $color-grey-dark;
        color: $color-grey-dark;
      }
    &.button--with-icon {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 4px 8px;
        :first-child {
            margin-right: 4px;
        }
        svg {
            width: 24px;
            height: 24px;
            fill: $color-grey-dark;
        }
    }
}

@media (max-width: $tablet) {
    button {
        &.button--with-icon {
            :first-child {
                margin-right: 0px;
            }
        }
    }
}