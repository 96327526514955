@import '../../variables.scss';

.input-container {
    margin-bottom: $margin-medium;
    input, textarea {
        width: 100%;
        opacity: 0.85;
        padding: $margin-small;
        line-height: 24px;
        background-size: 15px 15px;
        background-repeat: no-repeat;
        background-position: 0px center;
        border: none;
        transition: 0.2s;
        &:not(:placeholder-shown){ 
            color: $color-grey-dark;
            opacity: 1;
        }
        &:focus {
            outline: none;
            box-shadow: $box-shadow;
            opacity: 1;
        }
    }
    label {
        margin-top: 48px;
        color: white;
        font-size: 0.75rem;
        font-weight: 300;
    }
}

.input_submit {
    margin-bottom: $margin-medium;
    padding: $margin-small;
    outline: none;
    border: none;
    background: white;
    color: $color-grey-dark;
    width: 50%;
    transition: 0.2s;
    border-radius: none;
    &:focus { outline: none; }
    &.valid {
        opacity: 1;
        &:hover {
            box-shadow: $box-shadow;
            cursor: pointer;
        }
        &:active {
            box-shadow: none; 
        }
    }
    &.disabled {
        &:hover {
            cursor: not-allowed;
        }
        opacity: 0.5;
    }
}

@media (max-width: $phone) {
    .input-container {
        margin-bottom: $margin-small;
        label {
            margin-top: 48px;
            color: white;
            font-size: 0.75rem;
            font-weight: 300;
        }
    }
    .input_submit {
        width: 100%; 
    }
}