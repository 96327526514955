@import '../../variables.scss';

.nav-container {
    position: fixed;
    z-index: 4;
    right: 0;
    max-width: 100vw;
}

nav {
    position: relative;
    height: 100vh;
    padding: $margin-medium;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    transition: 0.6s;
    &.open {
        padding-right: $margin-large;
    }
}

.nav-background{
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    width: 30vw;
    background: linear-gradient(135deg, $color-primary, $color-secondary);
    height: 100vh;
    animation: navIn 0.6s;
}

@keyframes navIn {
    0% {
        opacity: 0;
        transform: translateX($margin-extra-large * 2);
    }
    50% {
        opacity: 0.8;
    }
}

@keyframes navOut {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.8;
    }
    100% {
        opacity: 0;
        transform: translateX($margin-extra-large * 2);
    }
}

@media (max-width: $phone) {
    nav {
        padding: $margin-small;
        &.open { padding-right: $margin-medium; }
    }
    .nav-background {
        width: 100vw;
    }
}