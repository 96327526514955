@import '../../variables.scss';

.body-paragraph {
    line-height: 32px;
    margin-top: $margin-medium;
    &.full {
        width: 100%;
    }
    &.half {
        width: 50%;
    }
}

// -------------- PHONE --------------
@media (max-width: $tablet) {
    .body-paragraph {
        &.half { width: 100%; }
    }
}