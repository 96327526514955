@import '../../variables.scss';

#menu {
    width: 48px;
    height: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    transition: 0.2s;
    animation: fadein2 2s; // animation located in home.scss
    .menu-line {
        background: white;
        height: 5px;
        transition: 0.4s;
        &:first-child { width: 100%; }
        &:nth-child(2) { width: 80%; }
        &:nth-child(3) { width: 60%; }
        &.colored { background: $color-grey-dark; }
    }
    &:hover {
        cursor: pointer;
        .menu-line { width: 100%; }
    }
    &.open {
        .menu-line { 
            width: 100%;
            background: white;
            &:first-child { transform: rotate(-45deg) translateY(360%); }
            &:nth-child(2) { 
                width: 1px; 
                visibility: hidden;
            }
            &:nth-child(3) { transform: rotate(45deg) translateY(-360%); }
        }
    }
}

@media (max-width: $phone) {
    #menu {
        width: 32px;
        height: 20px;
        .menu-line {
            height: 3px;
        }
    }
}