// This file holds the scss global values and media queries

// COLORS
$color-primary: #E72339;
$color-secondary: #E78523;
$color-grey-dark: #3B3B3B;
$color-grey-medium: #707070;
$color-grey-light: #D3D3D3;
$color-grey-lightest: #F8F8F8;
$box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.15);

// MARGINS & PADDINGS
$margin-extra-large: 144px;
$margin-large: 48px;
$margin-medium: 24px;
$margin-small: 12px;
$margin-extra-small: 4px;

// IMG AND ICONS
$img-medium: 24px;
$img-small: 16px;

// MEDIA QUERY SIZES
$phone: 640px;
$tablet: 1024px;
$desktop: 1024px;
