@import '../../variables.scss';

#about-me {
    position: relative;
    margin-top: $margin-extra-large * 3/2;
    margin-bottom: $margin-extra-large;
    z-index: -1;
    .about-me-title {
        color: $color-grey-dark;
        font-size: 3rem;
        font-weight: 200;
        text-transform: uppercase;
        margin-bottom: $margin-large;
        &.top-margin { margin-top: $margin-extra-large; }
        b { font-size: 3rem; }
    }
    .about-me-background-logo {
        position: absolute;
        transform: translate(40%, -120%);
        z-index: -1;
        pointer-events: none;
        width: 1400px;
        opacity: 0.12;
    }
}

// -------------- PHONE --------------
@media (max-width: $phone) {
    #about-me {
        margin-top: $margin-large * 2;
        margin-bottom: $margin-large;
        .about-me-title { 
            font-size: 2.5rem;
            b { font-size: 2.5rem; }
            &.top-margin { margin-top: $margin-large * 2; }
        }
        .about-me-background-logo { display: none; } 
    }
}

// -------------- TABLET --------------
@media (min-width: $phone) and (max-width: $tablet) {
    #about-me .about-me-background-logo { display: none; } 
}