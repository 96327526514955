@import '../../variables.scss';

.social-links {
    a {
        &:nth-child(2), &:nth-child(3) { margin-left: $margin-small }
        svg {
            width: 24px;
            height: 24px;
            fill: white;
            opacity: 0.8;
            transition: 0.2s;
        }
        &:hover > svg { 
            opacity: 1;
            transform: translateY(-2px); 
        }
        &:nth-child(3) {
            width: 20px;
            height: 20px; 
        }
    }
}


